<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="player listmain pcdd">

        <div class="data">
          <h3>总和龙虎</h3>
          <ul class="custom25">
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[0].arr" :key="item.ResultID">
              <ol class="td_name" style="width:55px">
                {{item.label}}
              </ol>
              <ol class="td_rate">
                <!---->
                {{
                item.Odds 
                }}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        <!-- <div class="flex-box"> -->
        <template v-for="(aaItem,index) in rowsData">

          <div class="data w20" v-if="index>0" :key="aaItem.title">
            <h3>{{aaItem.title}}</h3>
            <ul class="">
              <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in aaItem.arr" :key="item.ResultID">
                <ol class="td_name" style="width:55px">
                  {{item.label}}
                </ol>
                <ol class="td_rate">
                  <!---->
                  {{
                item.Odds 
                }}
                </ol>
                <ol class="td_cash">
                  <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                  <div class="quick-check" v-if="item.b">
                    <ul>
                      <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                        下注{{ qs.num }}元
                      </li>

                      <li @click="closeCheck(item)">关闭</li>
                    </ul>
                  </div>
                </ol>
              </li>
            </ul>
          </div>
        </template>
        <!-- </div> -->

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'qwlm',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "总和、龙虎", arr: [] },
        { title: "平码一", arr: [] },
        { title: "平码二", arr: [] },
        { title: "平码三", arr: [] },
        { title: "平码四", arr: [] },
        { title: "特码", arr: [] },
      ],
      labelArr1: [
        "合值大",
        "合值单",
        "合值尾大",
        "龙",
        "合值小",
        "合值双",
        "合值尾小",
        "虎",
      ],
      labelArr2: ["大", "小", "单", "双", "尾大", "尾小", "合单", "合双"],
    };
  },

  components: {
    topTimer,
    yushe,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title == "总和、龙虎" ? "" : item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };

                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        //总和、龙虎
        let arr1 = this.ddFilterData1(
          resAArr,
          [5364, 5366, 5368, 5418, 5365, 5367, 5369, 5419],
          this.labelArr1
        );
        this.rowsData[0].arr = arr1;
        // 平码一
        let arr2 = this.filterData(resAArr, 5370, 5377, this.labelArr2);
        this.rowsData[1].arr = arr2;
        // 平码二
        let arr3 = this.filterData(resAArr, 5378, 5385, this.labelArr2);
        this.rowsData[2].arr = arr3;
        // 平码三
        let arr4 = this.filterData(resAArr, 5386, 5393, this.labelArr2);
        this.rowsData[3].arr = arr4;
        // 平码四
        let arr5 = this.filterData(resAArr, 5394, 5401, this.labelArr2);
        this.rowsData[4].arr = arr5;
        // 特码
        let arr6 = this.filterData(resAArr, 5402, 5409, this.labelArr2);
        this.rowsData[5].arr = arr6;

        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.flex-box {
  display: flex;
}
.selected3 {
  position: relative;
  left: 100px;
  /* height: 26px; */
  display: flex;
  justify-content: center;
}
.listmain .data.w20 {
  width: 20%;
}
.listmain .data.w20 ul {
  display: block;
}
.listmain .data.w20 ul li {
  width: 100%;
}
.listmain > .data > ul.custom3 li:last-of-type .td_name {
  width: 100px;
}
.listmain > .data > ul.custom3 li:last-of-type {
  width: 100%;
}
.listmain > .data > ul.custom3 li {
  width: 25%;
}
.listmain > .data > ul.custom25 li {
  width: 25%;
}
.listmain > .data > ul.custom_ul1 li {
  height: 26px;
  max-height: 26px;
}
.listmain > .data > ul.custom_ul1 {
  width: 100%;
  height: 52px;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
  /* align-items: flex-start; */
}
.listmain > .data ul.custom_ul li {
  width: 25%;
  height: 30px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
  height: 210px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data ul li {
  width: 25%;
}

.listmain div.c2003 ul li {
  width: 50%;
}
.listmain > .data > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.listmain > .data > h3 {
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
}
.listmain div.c2003 {
  width: 100%;
}
.listmain .data {
  width: 100%;
}
.listmain > .data {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.listmain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  width: 100%;
  clear: both;
}
</style>
